import React, { useEffect, useState } from 'react';

import moment from 'moment';
import { useFormik } from 'formik';
import { Col, Row } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';

// GraphQL
import {
  CREATE_FILE_DATA_SEGMENT_MUTATION,
  CREATE_SEGMENT_MUTATION,
  UPDATE_USER_SEGMENT_MUTATION,
} from 'graphql/mutations/segments';
import {
  CALCULATE_FILE_SEGMENT,
  CALCULATE_USER_SEGMENT,
  GET_SEGMENT,
  GET_USER_SEGMENT_BY_PARENT_ID,
  GET_SEGMENTS,
} from 'graphql/queries/segments';

// utilities
import { formatNumber, getUserFeature } from 'Utils';

// Components
import PageHeader from 'components/PageHeader/PageHeader';
import PropTypes from 'prop-types';
import CustomSection from 'components/CustomSection/CustomSection';
import CustomButton from 'components/CustomButton/CustomButton';
import CustomInput from 'components/CustomInput/CustomInput';
import { useNotification } from 'components/Notification/Notification';
import Larky from 'components/Larky';
import ReactTooltip from 'react-tooltip';

// constants
import { limitList, ROUTES } from 'Constants';

// Services
import Analytics from 'libs/Analytics';
import { useAuthMachineValue } from 'contexts/auth-machine.context.ts';
import SegmentService from 'services/SegmentService';

import validationSchema from './CreateOrUpdateSegment.validator';

// Styles
import styles from './CreateOrUpdateSegment.module.scss';
import { getExcludedAndIncludeMembersOptions, getSegmentMembershipLimitOption } from './CreateOrUpdateSegment.utils';

import FileUpload from '../FileUpload/FileUpload';
import { useInitialFocus } from '../../../../hooks/useInitialFocus';
import UserService from '../../../../services/UserService';
import { GET_ALL_FEATURE_FLAGS_WITH_USER_ID } from '../../../../graphql/queries/organizations';
import { downloadAnalyticsDB } from '../../../../DashboardAnalyticsUtils';

// Local Constants
const FILE_NAME = 'CreateOrUpdateSegment.jsx';

const TimeLabel = ({ title, style, children }) => (
  <div className={styles['time-label']} style={style}>
    <h6>{title}</h6>
    {children}
  </div>
);

TimeLabel.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node,
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object,
};

TimeLabel.defaultProps = {
  style: {},
  children: null,
};

// start of CreateOrUpdateSegment
function CreateOrUpdateSegment({ organizationId }) {
  const notification = useNotification();
  const history = useHistory();
  const { segmentId } = useParams();
  useInitialFocus();

  const isUpdate = typeof segmentId !== 'undefined';
  const [currentAuth] = useAuthMachineValue();
  const { user } = currentAuth.context;

  const [createSegmentMutation] = useMutation(CREATE_SEGMENT_MUTATION);
  const [createFileDataSegmentMutation] = useMutation(CREATE_FILE_DATA_SEGMENT_MUTATION);
  const [updateUserSegmentMutation] = useMutation(UPDATE_USER_SEGMENT_MUTATION);

  const getSegmentsQuery = useQuery(GET_SEGMENTS, { skip: true });
  const calculateUserSegmentQuery = useQuery(CALCULATE_USER_SEGMENT, { skip: true });
  const getSegmentQuery = useQuery(GET_SEGMENT, { skip: true });
  const getUserSegmentByParentIdQuery = useQuery(GET_USER_SEGMENT_BY_PARENT_ID, { skip: true });
  const getAllFeatureFlagsQuery = useQuery(GET_ALL_FEATURE_FLAGS_WITH_USER_ID, { skip: true });

  const popupTriggerIncluded = { label: 'More System Groups...', value: 'popupIncluded' };
  const popupTriggerExcluded = { label: 'More System Groups...', value: 'popupExcluded' };

  const [userSegmentList, setUserSegmentList] = useState([]);
  const [systemSegmentList, setSystemSegmentList] = useState([]);
  const [trellanceSegmentList, setTrellanceSegmentList] = useState([]);
  const [hubSpotSegmentList, sethubSpotSegmentList] = useState([]);
  const [totalMembers, setTotalMembers] = useState(null);
  const [membershipRefreshedDate, setMembershipRefreshedDate] = useState(null);
  const [segmentDetails, setSegmentDetails] = useState({});
  const [initialValues, setInitialValues] = useState({});
  const [fileData, setFileData] = useState();
  const [isFileSelected, setIsFileSelected] = useState(false);
  const [userHasFileSegmentationPermission, setUserHasFileSegmentationPermission] = useState(false);
  const [userHasTrellanceSegmentationPermission, setUserHasTrellanceSegmentationPermission] = useState(false);
  const [userHashubSpotSegmentationPermission, setUserHashubSpotSegmentationPermission] = useState(false);

  const calculateSegmentFilDataQuery = useQuery(CALCULATE_FILE_SEGMENT, { skip: true });
  const [isDynamicSegmentEnabled, setIsDynamicSegmentEnabled] = useState(false);

  const [fileImportToolTipText, setFileImportToolTipText] = useState(false);
  const [fileImportUrl, setFileImportUrl] = useState(false);

  const [dynamicSegmentationToolTipText, setDynamicSegmentationToolTipText] = useState(false);
  const [dynamicSegmentationUrl, setDynamicSegmentationUrl] = useState(false);

  const [hasSegmentEditPermission, setHasSegmentEditPermission] = useState(false);
  const [refreshNowLoading, setrefreshNowLoading] = useState(false);
  const [selectFileWarning, setselectFileWarning] = useState(false)


  useEffect(async () => {
    const userHasSystemSegmentEditPermission = await getUserFeature('system_segmentation', 2);
    const userHasUserSegmentEditPermission = await getUserFeature('user_segmentation', 2);
    const userHasImportFileSegmentPermission = await getUserFeature('file_segmentation');
    const userHasTrellanceSegmentPermission = await getUserFeature('trellance_segmentation');
    const userHashubSpotSegmentPermission = await getUserFeature('hubspot_segmentation');
    const userHasDynamicSegmentPermission = await getUserFeature('dynamic_segmentation');
    await setHasSegmentEditPermission(userHasSystemSegmentEditPermission || userHasUserSegmentEditPermission);
    await setUserHasFileSegmentationPermission(userHasImportFileSegmentPermission);
    await setUserHasTrellanceSegmentationPermission(userHasTrellanceSegmentPermission);
    await setUserHashubSpotSegmentationPermission(userHashubSpotSegmentPermission);
    await setIsDynamicSegmentEnabled(userHasDynamicSegmentPermission);
  }, []);

  useEffect(async () => {
    const fileSegmentFeatureInto = await UserService.getFeatureFlagInfoWithUserId(getAllFeatureFlagsQuery, user.id, 'export_analytics');
    setFileImportToolTipText(fileSegmentFeatureInto.tooltip);
    setFileImportUrl(fileSegmentFeatureInto.tooltipUrl);
  }, [user.id]);

  useEffect(async () => {
    const dynamicSegmentationFeatureInfo = await UserService.getFeatureFlagInfoWithUserId(getAllFeatureFlagsQuery, user.id, 'dynamic_segmentation');
    setDynamicSegmentationToolTipText(dynamicSegmentationFeatureInfo.tooltip);
    setDynamicSegmentationUrl(dynamicSegmentationFeatureInfo.tooltipUrl);
  }, [user.id]);

  const onImportFromFileClick = async () => {
    if (userHasFileSegmentationPermission) {
      await formik.setFieldValue('dataType', 'file');
    }
    if (!userHasFileSegmentationPermission) {
      window.open(fileImportUrl, '_blank');
    }
    return null;
  };

  const onDynamicSegmentationDisabledClick = async () => {
    if (isDynamicSegmentEnabled) {
      await formik.setFieldValue('computeType', 'Dynamic');
    }
    if (!isDynamicSegmentEnabled && dynamicSegmentationUrl) {
      window.open(dynamicSegmentationUrl, '_blank');
    }
    return null;
  };

  const createToolTipTextFileSegmentation = () => `${fileImportToolTipText} Click to learn more!`;
  const createToolTipTextDynamicSegmentation = () => `${dynamicSegmentationToolTipText} Click to learn more!`;

  // formik start
  const formik = useFormik({
    initialValues: {
      name: '',
      description: '',
      includedMembers: [],
      excludedMembers: [],
      fileSelected: [],
      membershipLimit: '',
      updatedBy: user.id,
      isFileSelected: false,
      dataType: 'segment',
      computeType: 'Dynamic',
      isUpdate,
      ...initialValues,
    },
    validateOnChange: false,
    enableReinitialize: true,
    validationSchema,
    onSubmit: async ({
      name,
      description,
      includedMembers,
      excludedMembers,
      membershipLimit,
      updatedBy,
      computeType,
    }) => {
      try {
        if (formik.values.dataType === 'file') {
          if (isUpdate) {
            const payload = {
              name,
              description,
              membershipLimit: membershipLimit.value,
              updatedBy,
              organizationId,
              computeType,
            };
            await SegmentService.update(updateUserSegmentMutation, segmentId, payload);
          } else if (isFileSelected) {
            const payload = {
              name,
              description,
              membershipLimit: membershipLimit.value,
              federationId: fileData,
              updatedBy,
              organizationId,
              computeType,
            };
            await SegmentService.createFileDataSegment(createFileDataSegmentMutation, payload);
          }
          history.push(`${ROUTES.SEGMENTS.MAIN}`);
        } else if (formik.values.dataType === 'segment' || formik.values.dataType === 'user') {
          const segmentPayload = {
            name,
            description,
            includedMembers: includedMembers.map((x) => x.value),
            excludedMembers: excludedMembers.map((x) => x.value),
            membershipLimit: membershipLimit.value,
            updatedBy,
            organizationId,
            computeType,
          };
          if (isUpdate) {
            await SegmentService.update(updateUserSegmentMutation, segmentId, segmentPayload);
          } else {
            await SegmentService.create(createSegmentMutation, segmentPayload);
          }
          history.push(`${ROUTES.SEGMENTS.MAIN}`);
        }
      } catch (err) {
        notification.alert(err.message, 'danger');
        Analytics.trackApplicationError(err.message, FILE_NAME, 'onSubmit');
      }
    },
  });

  useEffect(async () => {
    const disableButtonForComputeType = async () => {
      if (!isDynamicSegmentEnabled && (formik.values.computeType.toLowerCase() === 'dynamic')) {
        await formik.setFieldValue('computeType', 'Static');
      }
      if (isDynamicSegmentEnabled && formik.values.computeType.toLowerCase() === 'static') {
        await formik.setFieldValue('computeType', 'Dynamic');
      }
      return () => {};
    };

    const allowDynamicSegmentCheck = async () => {
      let allowed = true;
      const allSystemSegmentIds = [];
      if ((systemSegmentList && systemSegmentList.length) || (trellanceSegmentList && trellanceSegmentList.length)
          || (hubSpotSegmentList && hubSpotSegmentList.length)) {
        systemSegmentList.forEach((member) => {
          allSystemSegmentIds.push(member.value);
        });
        trellanceSegmentList.forEach((member) => {
          allSystemSegmentIds.push(member.value);
        });
        hubSpotSegmentList.forEach((member) => {
          allSystemSegmentIds.push(member.value);
        });
      }
      if (formik.values.includedMembers) {
        for (let i = 0; i < formik.values.includedMembers.length; i++) {
          if (!allSystemSegmentIds.includes(parseInt(formik.values.includedMembers[i].value))) {
            allowed = false;
            break;
          }
        }
      }

      if (formik.values.excludedMembers) {
        for (let j = 0; j < formik.values.excludedMembers.length; j++) {
          if (!allSystemSegmentIds.includes(parseInt(formik.values.excludedMembers[j].value))) {
            allowed = false;
            break;
          }
        }
      }
      const userHasDynamicSegmentPermission = await getUserFeature('dynamic_segmentation');
      const allowedWithPermissions = allowed && userHasDynamicSegmentPermission;
      setIsDynamicSegmentEnabled(allowedWithPermissions);
      return null;
    };
    await allowDynamicSegmentCheck();
    await disableButtonForComputeType();
  }, [systemSegmentList, formik.values.includedMembers, formik.values.excludedMembers, isDynamicSegmentEnabled]);

  // use effect section starts
  useEffect(() => {
    async function fetchSegments() {
      try {
        const userSearchParams = {
          segmentType: 'user-file',
        };

        const userSegments = await SegmentService.getSegments(
          getSegmentsQuery,
          userSearchParams,
        );

        const systemSearchParams = {
          segmentType: 'system',
        };

        const systemSegments = await SegmentService.getSegments(
          getSegmentsQuery,
          systemSearchParams,
        );

        const trellanceSearchParams = {
          segmentType: 'trellance',
        };

        const hubSpotSearchParams = {
          segmentType: 'hubspot',
        };

        const trellanceSegments = await SegmentService.getSegments(
          getSegmentsQuery,
          trellanceSearchParams,
        );


        const hubspotSegments = await SegmentService.getSegments(
          getSegmentsQuery,
          hubSpotSearchParams,
        );

        const mapSegment = (segment) => ({
          // eslint-disable-next-line radix
          value: parseInt(segment.id),
          label: segment.segmentName,
        });

        setUserSegmentList(userSegments.hits.map(mapSegment));
        setSystemSegmentList(systemSegments.hits.map(mapSegment));
        setTrellanceSegmentList(trellanceSegments.hits.map(mapSegment));
        sethubSpotSegmentList(hubspotSegments.hits.map(mapSegment));
      } catch (err) {
        Analytics.trackApplicationError(err.message, FILE_NAME, 'fetchSegments');
        notification.alert('Failed to fetch segments', 'danger');
      }
    }

    function showSystemSegmentPopup(includedOrExcludedBool) {
      let popupIndex = 0;
      if (includedOrExcludedBool === 'included') {
        for (const i in formik.values.includedMembers) {
          if (formik.values.includedMembers.some((option) => option.value === 'popupIncluded')) {
            popupIndex = i;
          }
        }
        formik.values.includedMembers.pop(popupIndex);
      }

      if (includedOrExcludedBool === 'excluded') {
        for (const i in formik.values.excludedMembers) {
          if (formik.values.excludedMembers.some((option) => option.value === 'popupExcluded')) {
            popupIndex = i;
          }
        }
        formik.values.excludedMembers.pop(popupIndex);
      }

      notification.fullscreenSystemSegmentDialog(organizationId, user.id, (response) => {
        if (response.segment) {
          addNewSystemSegment(response.segment, includedOrExcludedBool);
        }
      });
    }

    function addNewSystemSegment(segment, includedOrExcludedBool) {
      fetchSegments();

      const segmentListObject = {
        value: segment.id,
        label: segment.segmentName,
      };

      if (includedOrExcludedBool === 'included') {
        formik.values.includedMembers.push(segmentListObject);
      }
      if (includedOrExcludedBool === 'excluded') {
        formik.values.excludedMembers.push(segmentListObject);
      }
    }

    if (formik.values.includedMembers) {
      if (formik.values.includedMembers.some((option) => option.value === 'popupIncluded')) {
        showSystemSegmentPopup('included');
      }
    }

    if (formik.values.excludedMembers) {
      if (formik.values.excludedMembers.some((option) => option.value === 'popupExcluded')) {
        showSystemSegmentPopup('excluded');
      }
    }

    async function fetchSegment() {
      if (isUpdate && Object.keys(initialValues).length === 0) {
        try {
          const segment = await SegmentService.index(getSegmentQuery, segmentId);
          setSegmentDetails(segment);
          const userSegments = await SegmentService.getUserSegmentByParentId(getUserSegmentByParentIdQuery, segmentId);
          const { includedMembers, excludedMembers } = getExcludedAndIncludeMembersOptions(userSegments);
          const segmentMembershipLimit = segment.membershipLimit ? getSegmentMembershipLimitOption(segment.membershipLimit) : '';

          const newInitialValues = {
            name: segment.segmentName,
            description: segment.segmentDesc,
            includedMembers,
            excludedMembers,
            membershipLimit: segmentMembershipLimit,
            updatedBy: user.id,
            dataType: segment.segmentType,
            computeType: segment.computeType,
          };

          setInitialValues({ ...newInitialValues });
        } catch (err) {
          Analytics.trackApplicationError(err.message, FILE_NAME, 'fetchSegment');
          notification.alert(err.message, 'danger');
        }
      }
    }
    if (isUpdate) {
      fetchSegment();
    }
    fetchSegments();
  },
  [
    getSegmentsQuery,
    formik.values.includedMembers,
    formik.values.excludedMembers,
    isUpdate,
    initialValues,
    setInitialValues,
  ]);

  const handleSubmitForm = async () => {
    // Formik tip, helpful for debugging
    // validationSchema().validate(formik.values)
    //   .catch(function(e) {
    //     console.log(e);
    //   });

    // this helps validationSchema determine if a file was selected by the user
    if (formik.values.dataType === 'file') {
      if (!isFileSelected) {
      setselectFileWarning("No file selected. Please select a file to proceed.");
      return;
    }

      formik.values.fileSelected = isFileSelected ? [fileData] : [];
      await formik.setFieldValue('computeType', 'static');
      setselectFileWarning(false)
    }

    if (formik.values.excludedMembers === null) formik.values.excludedMembers = [];
    formik.submitForm();
  };

  const setMembershipTotal = (membershipCount) => {
    setTotalMembers(membershipCount);
    setMembershipRefreshedDate(moment(new Date()).format('YYYY-MM-DD hh:mm:ss'));
  };

  async function calculateUserMembershipSegment() {
    const membershipLimit = formik.values.membershipLimit.value || null;
    const segmentIncluded = formik.values.includedMembers.map((x) => x.value);
    const segmentExcluded = formik.values.excludedMembers.map((x) => x.value);

    return SegmentService.calculateUserSegment(
      calculateUserSegmentQuery,
      organizationId,
      user.id,
      membershipLimit,
      segmentIncluded,
      segmentExcluded,
    );
  }
  async function calculateFileMembershipSegment() {
    const membershipLimit = formik.values.membershipLimit.value || null;

    return SegmentService.calculateSegmentFileData(
      calculateSegmentFilDataQuery,
      organizationId,
      user.id,
      fileData,
      membershipLimit,
    );
  }
  async function calculateMembership() {
    if (formik.values.includedMembers !== null) {
    setrefreshNowLoading(true);
    const formikDataType = formik.values.dataType ? formik.values.dataType.toLowerCase() : formik.values.dataType;
    const isSegmentUser = (formikDataType === 'segment' || formikDataType === 'user');
    const membershipTotal = isSegmentUser
      ? await calculateUserMembershipSegment() : await calculateFileMembershipSegment();

    setMembershipTotal(membershipTotal.count);
    setrefreshNowLoading(false);
    }
  }

  const isRefreshNowBtnDisabled = () =>
  // for now refresh now is disabled for File-based segments, maybe in the future this will change
  formik.values.dataType === 'file' || (formik.values.includedMembers ? formik.values.includedMembers.length === 0 : true);


  const getTitle = () => {
    if (isUpdate) {
      return 'Edit this Segment';
    }
    return 'Create a Segment';
  };

  const setSubmitButtonText = () => {
    if (!isUpdate) {
      return 'Create Segment';
    }
    return 'Update Segment';
  };

  const isDisabled = () => {
    if (!hasSegmentEditPermission) {
      return true;
    }
    return isUpdate;
  };

  const isMembershipLimitDisabled = () =>
    // currently membership limit should NOT be available when editing file based segments
    isUpdate && (segmentDetails.segmentType === 'file');
  const isOutlined = (segmentType) => {
    if (isUpdate) {
      const type = segmentDetails.segmentType
        ? segmentDetails.segmentType.toLowerCase() : segmentDetails.segmentType;
      return type !== segmentType;
    }
    // segmentType 'User' maps to 'segment'
    const type = segmentType.toLowerCase() === 'user' ? 'segment' : segmentType.toLowerCase();
    return formik.values.dataType !== type;
  };

  const dynamicButtonOutline = (computeType) => formik.values.computeType.toLowerCase() !== computeType;

  const displaySegment = () => {
    if (isUpdate) {
      const type = segmentDetails.segmentType
        ? segmentDetails.segmentType.toLowerCase() : segmentDetails.segmentType;
      return type === 'user';
    }
    return formik.values.dataType === 'segment';
  };

  return (
    <div className={styles.root}>
      <Row style={{ paddingLeft: 24 }}>
        <PageHeader
          title={getTitle()}
          subtitle="Define a segment of your account holders to fine-tune your messaging"
        />
      </Row>

      <form onSubmit={formik.handleSubmit}>
        {/* NAME SEGMENT */}
        <Row className={styles.section}>
          <CustomSection title="Name your segment">
            <h4>Unlike nudges, which get delivered to recipients, all details of segments are only visible to you!</h4>
            <Row className={styles.section}>
              <Col xs={12} style={{ padding: 0 }}>
                <CustomInput
                  type="text"
                  name="name"
                  title="Name"
                  placeholder="e.g. All tapped nudges past 30 days"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  error={formik.errors.name}
                />

                <CustomInput
                  type="textarea"
                  name="description"
                  title="Give your segment a description (optional)"
                  placeholder="e.g. Used to identify our most-engaged members"
                  value={formik.values.description}
                  onChange={formik.handleChange}
                  error={formik.errors.description}
                />
              </Col>
            </Row>
            <div className={styles.horizontal} />
          </CustomSection>
        </Row>

        {/* SELECT SEGMENT BUILDING METHOD */}
        <Row className={styles.section}>
          <CustomSection title="Select segment source">
            <Row style={{ margin: 0 }}>
              <Col xs={12} md="auto" style={{ padding: 0 }}>
                {/* Exsisting Segments Button */}
                <Larky.Button
                  className={styles.button}
                  onClick={() => formik.setFieldValue('dataType', 'segment')}
                  outlined={isOutlined('user')}
                  disabled={isDisabled()}
                  size="xl"
                >
                  From Existing Segments
                </Larky.Button>
              </Col>

              <Col className={styles.pl} xs={12} md="auto" style={{ padding: 0 }}>
                {/* Select from File Button */}
                <Larky.Button
                  onClick={() => onImportFromFileClick()}
                  outlined={isOutlined('file')}
                  data-tip={!userHasFileSegmentationPermission ? createToolTipTextFileSegmentation() : null}
                  size="xl"
                  className={formik.isSubmitting || !userHasFileSegmentationPermission ? styles['disable-type-button'] : ''}
                >
                  Import from a File
                </Larky.Button>
                <ReactTooltip />
              </Col>
            </Row>
          </CustomSection>
        </Row>
        {/* Only shown when 'segment' type is selected */}
        {displaySegment() && (
          <>
            {/* DEFINE SEGMENT INCLUDE AND EXCLUDE */}
            <Row className={styles.section}>
              <CustomSection title="Define your segment">
                <h4>Specify the details of who should be included in this segment</h4>
                <Row style={{ margin: 0 }}>
                  <Col md="auto" style={{ padding: 0, width: '152%' }}>
                    <Col xs={15} style={{ paddingLeft: '10px' }}>
                      <CustomInput
                        type="select"
                        name="selectInclude"
                        title="Include members from any of the following other segments or system groups"
                        placeholder="Select..."
                        onChange={(values) => (formik.setFieldValue('includedMembers', values))}
                        value={formik.values.includedMembers}
                        error={formik.errors.includedMembers}
                        options={
                          [

                          ...(userHashubSpotSegmentationPermission && hubSpotSegmentList.length > 0
                            ? [{
                            label: 'LARKY CONDUIT SOURCE: HUBSPOT',
                            options: formik.values.excludedMembers ? hubSpotSegmentList.filter(
                              (option) => !formik.values.excludedMembers.includes(option),
                            ) : hubSpotSegmentList,
                          }]: []),
                          ...(userHasTrellanceSegmentationPermission && trellanceSegmentList.length > 0
                            ? [{
                            label: 'LARKY CONDUIT SOURCE: TRELLANCE',
                            options: formik.values.excludedMembers ? trellanceSegmentList.filter(
                              (option) => !formik.values.excludedMembers.includes(option),
                            ) : trellanceSegmentList,
                          }]: []),
                          {
                            label: 'Segments',
                            options: formik.values.excludedMembers ? userSegmentList.filter(
                              (option) => !formik.values.excludedMembers.includes(option),
                            ) : userSegmentList,
                          },
                          {
                            label: 'System Groups',
                            options: formik.values.excludedMembers ? systemSegmentList.filter(
                              (option) => !formik.values.excludedMembers.includes(option),
                            ) : systemSegmentList,
                          },
                          popupTriggerIncluded,
                          ]
                      }
                        isMulti
                      />
                    </Col>

                    <Col xs={15} style={{ paddingLeft: '10px' }}>
                      <CustomInput
                        type="select"
                        name="selectExclude"
                        title="Exclude all members from the following other segments and system groups (optional)"
                        placeholder="Select..."
                        onChange={(values) => formik.setFieldValue('excludedMembers', values)}
                        value={formik.values.excludedMembers}
                        style={{ minWidth: 20 }}
                        options={
                          [
                          ...(userHashubSpotSegmentationPermission && hubSpotSegmentList.length > 0
                            ? [{
                            label: 'LARKY CONDUIT SOURCE: HUBSPOT',
                            options: formik.values.includedMembers ? hubSpotSegmentList.filter(
                              (option) => !formik.values.includedMembers.includes(option),
                            ) : hubSpotSegmentList,
                          }]: []),
                          ...(userHasTrellanceSegmentationPermission && trellanceSegmentList.length > 0
                                        ? [{
                            label: 'LARKY CONDUIT SOURCE: TRELLANCE',
                            options: formik.values.includedMembers ? trellanceSegmentList.filter(
                              (option) => !formik.values.includedMembers.includes(option),
                            ) : trellanceSegmentList,
                          }] : []),
                          {
                            label: 'Segments',
                            options: formik.values.includedMembers ? userSegmentList.filter(
                              (option) => !formik.values.includedMembers.includes(option),
                            ) : userSegmentList,
                          },
                          {
                            label: 'System Groups',
                            options: formik.values.includedMembers ? systemSegmentList.filter(
                              (option) => !formik.values.includedMembers.includes(option),
                            ) : systemSegmentList,
                          },
                          popupTriggerExcluded,
                          ]
                        }
                        isMulti
                      />
                    </Col>
                  </Col>
                </Row>
                <div className={styles.horizontal} />
              </CustomSection>
            </Row>
            {/* SELECT SEGMENT BUILDING METHOD */}
            <Row className={styles.section}>
              <CustomSection
                title="Select segment type"
                subheader={(!isDynamicSegmentEnabled && dynamicSegmentationToolTipText) ? (
                  <div>
                    {' '}
                    {dynamicSegmentationToolTipText}
                    {' '}
                    Click
                    {' '}
                    <a href={`${dynamicSegmentationUrl}`} target="_blank" rel="noopener noreferrer">here</a>
                    {' '}
                    to learn more.
                    {' '}
                  </div>
                ) : null}
              >
                <Row style={{ margin: 0 }}>
                  <Col xs={12} md="auto" style={{ padding: 0 }}>
                    {/* Exsisting Segments Button */}
                    <Larky.Button
                      className={formik.isSubmitting || !isDynamicSegmentEnabled ? styles['disable-type-button'] : ''}
                      onClick={() => onDynamicSegmentationDisabledClick()}
                      data-tip={!isDynamicSegmentEnabled ? createToolTipTextDynamicSegmentation() : null}
                      outlined={dynamicButtonOutline('dynamic')}
                      size="xl"
                    >
                      Dynamic - Refreshes Automatically
                    </Larky.Button>
                    <ReactTooltip />
                  </Col>

                  <Col className={styles.pl} xs={12} md="auto" style={{ padding: 0 }}>
                    {/* Select from File Button */}
                    <Larky.Button
                      onClick={async () => await formik.setFieldValue('computeType', 'Static')}
                      outlined={dynamicButtonOutline('static')}
                      size="xl"
                      className={formik.isSubmitting ? styles['disable-type-button'] : ''}
                    >
                      Static
                    </Larky.Button>
                    {/* <ReactTooltip /> */}
                  </Col>
                </Row>
              </CustomSection>
            </Row>
          </>
        )}
        {/* Only shown when 'segment' type is selected */}
        {!displaySegment() && (
          <>
            <FileUpload
              organizationId={organizationId}
              userId={user.id}
              title="Define your segment"
              header="Specify the file to import and confirm details"
              setFileDataToUpload={setFileData}
              setMembershipTotalCount={setMembershipTotal}
              setIsFileSelected={setIsFileSelected}
              disabled={isDisabled()}
              error={formik.errors.fileSelected}
              selectFileWarning={selectFileWarning}
            />
          </>
        )}

        {/* MEMBERSHIP LIMIT */}
        <Row className={styles.section}>
          <CustomSection title="Define membership limit (optional)">
            <h4>
              You can limit the total number of members for this segment;
              you might do this if you are testing messaging
            </h4>
            <Row style={{ margin: 0 }}>
              <Col md="auto" style={{ padding: 0 }}>
                <Col>
                  <h4 style={{ fontWeight: 'bold', color: '#2FBFFE' }}>
                    A random sampling will be taken from all possible matching recipients defined above
                  </h4>
                </Col>
                <Col xs={4}>
                  <CustomInput
                    type="select"
                    name="selectLimit"
                    placeholder="No Limit"
                    onChange={(values) => { formik.setFieldValue('membershipLimit', values); }}
                    value={formik.values.membershipLimit}
                    options={limitList}
                    disabled={isMembershipLimitDisabled()}
                  />
                </Col>
              </Col>
            </Row>
            <div className={styles.horizontal} />
          </CustomSection>
        </Row>

        {/* APPROXIMATE TOTAL MEMBERS */}
        <Row className={styles.section}>
          <CustomSection title="Approximate total members">
            <Row style={{ margin: 0 }} md="auto">
              <Col className={styles.pl} xs={12} md="auto" style={{ padding: 0 }}>
                <h5 style={{ marginRight: 15, fontWeight: 'bold' }}>
                  {(totalMembers !== null
                    ? `Based on our criteria, there are approximately ${formatNumber(totalMembers)} members in this segment`
                    : 'Click Refresh now to view membership total')}
                </h5>
              </Col>

              <Col xs={12} md="auto" style={{ padding: 0 }}>
                <Row>
                  <TimeLabel title="Last Refreshed:" style={{ marginLeft: 100 }} />
                  <h5 style={{ margin: 'auto' }}>{membershipRefreshedDate || ''}</h5>
                </Row>
                <Row style={{ marginLeft: 175 }}>
                  <CustomButton
                    variant="contained"
                    size="xs"
                    text="Refresh Now"
                    onClick={() => calculateMembership()}
                    disabled={isRefreshNowBtnDisabled()}
                    isLoading={refreshNowLoading}
                  />
                </Row>
              </Col>
            </Row>
            <div className={styles.horizontal} />
          </CustomSection>
        </Row>

        {/* SAVE BUTTONS */}
        <Row className={styles.horizontal} style={{ justifyContent: 'center' }}>
          <Col xs={12} md="3">
            <CustomButton
              variant="outlined"
              size="lg"
              text="Cancel"
              onClick={() => history.goBack()}
            />
          </Col>
          <Col xs={12} md="auto">
            <CustomButton
              variant="contained"
              size="lg"
              text={setSubmitButtonText()}
              onClick={() => { handleSubmitForm(); }}
              disabled={formik.isSubmitting || !hasSegmentEditPermission}
              // className={formik.isSubmitting || !hasSegmentEditPermission ? styles['disable-type-button'] : ''}
              isLoading={formik.isSubmitting}
            />
          </Col>
        </Row>
      </form>

    </div>
  );
}

CreateOrUpdateSegment.propTypes = {
  organizationId: PropTypes.number,
};

CreateOrUpdateSegment.defaultProps = {
  organizationId: null,
};

export default CreateOrUpdateSegment;
