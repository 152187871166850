/**
 * CreateOrUpdateCampaign.jsx
 *
 * Component that contains form to create or update a Campaign
 *
 */
import React, { useEffect, useState } from 'react';

import { useFormik } from 'formik';
import { Col, Container, Row } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';

// Components
import InitialLoader from 'components/InitialLoader/InitialLoader';
import PageHeader from 'components/PageHeader/PageHeader';
import PropTypes from 'prop-types';
import CustomSection from 'components/CustomSection/CustomSection';
import CustomButton from 'components/CustomButton/CustomButton';
import CustomInput from 'components/CustomInput/CustomInput';
import { useNotification } from 'components/Notification/Notification';

// constants
import {
  ROUTES, INPUT_DATE_FORMAT, TAP_BEHAVIOR,
} from 'Constants';

import {
  getUserSegmentDropdownOptions,
  getCampaignUserSegmentFromList, getUserFeature, ALL_POSSIBLE_RECIPIENTS_OPTION,getTrellanceSegmentDropdownOptions,
  getTrellanceSegmentFromList,getCampaignTrellanceSegmentFromList,getCampaignHubspotSegmentFromList
  ,getHubSpotSegmentDropdownOptions,getHubSpotSegmentFromList
} from 'Utils';

// Services
import Analytics from 'libs/Analytics';
import { useAuthMachineValue } from 'contexts/auth-machine.context.ts';
import CampaignService from 'services/CampaignService';
import SegmentService from 'services/SegmentService';
import NudgeService from 'services/NudgeService';

// GraphQL
import {
  CREATE_CAMPAIGN_MUTATION,
  UPDATE_CAMPAIGN_MUTATION,
  ACTIVATE_CAMPAIGN_MUTATION,
  UPDATE_CAMPAIGN_ORIGINAL_TEMPLATE_MUTATION,
} from 'graphql/mutations/campaigns';
import { GET_CAMPAIGN, GET_CAMPAIGN_MESSAGES } from 'graphql/queries/campaigns';
import { GET_SEGMENTS } from 'graphql/queries/segments';
import {
  GET_NUDGES_RECEIVED,
  GET_NUDGES_TAPPED,
  GET_NUDGE_AND_CAMPAIGN_CATEGORIES
} from 'graphql/queries/nudges';
import moment from 'moment';
import CampaignTemplateEditModal from '../CampaignGallery/CampaignTemplateEditModal/CampaignTemplateEditModal';
import validationSchema from './CreateOrUpdateCampaign.validator';
import activationValidationSchema from './ActivateCampaign.validator';
import styles from './CreateOrUpdateCampaign.module.scss';
import CampaignMessages from '../CampaignMessages/CampaignMessages';
import CustomRadioButton from '../../../../components/CustomRadioButton/CustomRadioButton';
import Larky from '../../../../components/Larky';
import AnalyticsModal from '../../../Nudges/components/AnalyticsModal/AnalyticsModal';
import { NUMBER_OF_DAYS_TO_SHOW_ON_DOWNLOAD } from '../../../../Constants';
import { GET_NUDGES_HEADERS } from '../../../../graphql/queries/nudges';
import { useInitialFocus } from '../../../../hooks/useInitialFocus';
import ButtonImage from "../../../Nudges/components/CreateOrUpdateNudge/nudge Assist - Campaign.png";
import ToolTipIcon from "../../../../components/ToolTipIcon/ToolTipIcon";
import UserService from "../../../../services/UserService";
import {GET_ALL_FEATURE_FLAGS_WITH_USER_ID} from "../../../../graphql/queries/organizations";

// Local Constants
const FILE_NAME = 'CreateOrUpdateCampaign.jsx';

function CreateOrUpdateCampaign({ organizationId }) {
  const notification = useNotification();
  const history = useHistory();
  const { campaignId } = useParams();
  const [currentAuth] = useAuthMachineValue();
  useInitialFocus();
  const { user } = currentAuth.context;
  const isUpdate = typeof campaignId !== 'undefined';
  const [initialValues, setInitialValues] = useState({});
  const [campaignDetails, setCampaignDetails] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [userSegmentList, setUserSegmentList] = useState([]);
  const [trellanceSegmentList, setTrellanceSegmentList] = useState([]);
  const [hubSpotSegmentList, setHubSpotSegmentList] = useState([]);
  const [radioOptions, setRadioOptions] = useState(TAP_BEHAVIOR);
  const [activatedCampaign, setActivatedCampaign] = useState(false);
  const [containsNudges, setContainsNudges] = useState(false);
  const [validationWarning, setValidationWarning] = useState(false);
  const [dateValid, setDateValid] = useState(false);
  const [categoryWarning, setCategoryWarning] = useState(false)
  const [nudgeassistWarning, setnudgeassistWarning] = useState(false)
  const [userHasNudgeAssist, setUserNudgeAssist] = useState(false);
  const [NudgeAssistToolTipText, setNudgeAssistToolTipText] = useState(false);
  const [NudgeAssistUrl, setNudgeAssistUrl] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showModalNudge, setShowModalNudge] = useState(false);
  const [selectedCampaignNudge, setSelectedCampaignNudge] = useState(null);
  const [showAnalytics, setShowAnalytics] = useState(true);
  const [showTemplateCreatorModal, setShowTemplateCreatorModal] = useState(false);
  const [templateId, setTemplateId] = useState(null);
  const [updateTemplate, setUpdateTemplate] = useState(null);
  const [temporaryFlag, setTemporaryFlag] = useState(false);
  const [campaignCategoryOptions, setCampaignCategoryOptions] = useState([]);
  const [userHasCampaignsFeatureReadOnly, setUserHasCampaignsFeatureReadOnly] = useState(false);
  const getNudgesReceivedQuery = useQuery(GET_NUDGES_RECEIVED, { skip: true });
  const getNudgesTappedQuery = useQuery(GET_NUDGES_TAPPED, { skip: true });
  const getNudgesHeadersQuery = useQuery(GET_NUDGES_HEADERS, { skip: true });
  const getCampaignMessageCategories = useQuery(GET_NUDGE_AND_CAMPAIGN_CATEGORIES, { skip: true })
  const ENVIRONMENT = currentAuth.context.user.environment;

  const [createCampaignMutation] = useMutation(CREATE_CAMPAIGN_MUTATION);
  const [updateCampaignMutation] = useMutation(UPDATE_CAMPAIGN_MUTATION);
  const [activateCampaignMutation] = useMutation(ACTIVATE_CAMPAIGN_MUTATION);
  const [updateCampaignTemplateOriginalMutation] = useMutation(UPDATE_CAMPAIGN_ORIGINAL_TEMPLATE_MUTATION);
  const getCampaignQuery = useQuery(GET_CAMPAIGN, { skip: true });
  const getSegmentsQuery = useQuery(GET_SEGMENTS, { skip: true });
  const getCampaignMessagesQuery = useQuery(GET_CAMPAIGN_MESSAGES, { skip: true });
  const isTemplateCreator = user && user.featureFlags && user.featureFlags.template_creator > 1;
  const getAllFeatureFlagsQuery = useQuery(GET_ALL_FEATURE_FLAGS_WITH_USER_ID, {skip: true});
  const [userHasTrellanceSegmentationPermission, setUserHasTrellanceSegmentationPermission] = useState(false);
  const [userHasHubspotSegmentationPermission, setUserHasHubSpotSegmentationPermission] = useState(false);
  // formik start
  const formik = useFormik({
    initialValues: {
      name: '',
      description: '',
      nudgeassist:'',
      organizationId,
      updatedBy: user.id,
      isUpdate,
      inclSegmentId: null,
      tapBehavior: 0,
      startDate: null,
      endDate: null,
      status: 0,
      showAnalytics: true,
      archived: false,
      templateId: null,
      campaignCategory: null,
      ...initialValues,
    },
    validateOnChange: false,
    enableReinitialize: true,
    validationSchema,
    activationValidationSchema,
    onSubmit: async ({
      name,
      description,
      nudgeassist,
      updatedBy,
      inclSegmentId,
      tapBehavior,
      startDate,
      endDate,
      status,
      campaignCategory
    }) => {
      try {
        setnudgeassistWarning(false);
        const campaignMessages = campaignId
          ? await CampaignService.getCampaignNudges(
            getCampaignMessagesQuery, organizationId, campaignId,
          )
          : null;
        const formattedStartDate = moment(startDate, INPUT_DATE_FORMAT).toDate();
        const formattedEndDate = moment(endDate, INPUT_DATE_FORMAT).toDate();
        let campaignCreated;
        let campaignPayload = {
          name,
          description,
          nudgeassist,
          organizationId,
          updatedBy,
          startDate: formattedStartDate,
          endDate: formattedEndDate,
          status,
        };
        if (campaignCategory) {
          campaignPayload.campaignCategoryId = campaignCategory.value;
        }
        if (isUpdate) {
          let segmentId = null;
          segmentId = inclSegmentId === null ? null : inclSegmentId.value;

          campaignPayload = {
            inclSegmentId: segmentId,
            tapBehavior,
            ...campaignPayload,
          };
          campaignMessages.hits.length ? setContainsNudges(true) : setValidationWarning(false);
          campaignPayload.status === 1 && containsNudges
            ? await CampaignService.activateCampaign(activateCampaignMutation, campaignId, campaignPayload)
            : await CampaignService.updateCampaign(updateCampaignMutation, campaignId, campaignPayload);
        }
        if (templateId && updateTemplate) {
          const campaignTemplateUpdatePayload = {
            campaignId,
          };
          await CampaignService.updateCampaignOriginalTemplate(
            updateCampaignTemplateOriginalMutation,
            templateId,
            campaignTemplateUpdatePayload,
          );
        }
        if (!isUpdate && !updateTemplate) {
          campaignCreated = await CampaignService.createCampaign(createCampaignMutation, campaignPayload);
        }
        if (!activatedCampaign && isUpdate) {
          campaignMessages.hits.length ? setActivatedCampaign(true) : setValidationWarning(true);
        }
        if (campaignPayload.status === 1 && containsNudges) {
          setActivatedCampaign(true);
          setInitialValues({ ...campaignPayload });
        }
        if (campaignPayload.status === 2 && initialValues.status === 1) {
          setActivatedCampaign(false);
          setInitialValues({ ...formik.values });
        } else if (updateTemplate) {
          history.push(`${ROUTES.CAMPAIGNS.GALLERY}`);
        } else if (!isUpdate && !updateTemplate && templateId) {
          history.push(`${ROUTES.CAMPAIGNS.GALLERY}`);
        } else if (!isUpdate) {
          history.push(`${ROUTES.CAMPAIGNS.EDIT}/${campaignCreated.id}`);
        } else if (showTemplateCreatorModal) {
          return null;
        } else {
          history.push(`${ROUTES.CAMPAIGNS.MAIN}`);
        }
      } catch (err) {
        // notification.alert(err.message, 'danger');
        // Analytics.trackApplicationError(err.message, FILE_NAME, 'onSubmit');
        console.log(err)
        setnudgeassistWarning(err.message);
        return;
      }
    },
  });

  const showAnalyticsPopup = () => {
    setShowModal(true);
  };
  const showAnalyticsNudgePopup = (nudgeData) => {
    setSelectedCampaignNudge(nudgeData);
    setShowModalNudge(true);
  };
  const hideModalCallback = () => {
    setShowModal(false);
  };
  const hideModalCallbackNudge = () => {
    setShowModalNudge(false);
    setSelectedCampaignNudge(null);
  };

  const handleRadioButtonChange = (value) => {
    formik.setFieldValue('tapBehavior', value);
    return radioOptions.map((option) => {
      if (option.value === value) {
        option.selected = true;
      }
      if (option.value !== value) {
        option.selected = false;
      }
      return option;
    });
  };

  useEffect(async() => {
    const getMessageCategories = async () => {
      try {
        // call for categories
        const campaignCategoryResponse = await NudgeService.getNudgeAndCampaignCategories(
            getCampaignMessageCategories,
        );
        setCampaignCategoryOptions(campaignCategoryResponse)
      } catch (e) {
        console.log(e.message)
      }
    }
    await getMessageCategories()
  }, [])

  useEffect(async () => {
    const hasCampaignsFeatureReadOnly = await getUserFeature('campaigns', 1);
    await setUserHasCampaignsFeatureReadOnly(hasCampaignsFeatureReadOnly);
    const HasNudgeAssist = await getUserFeature('nudge_assist');
    const hastrellanceSegments = await getUserFeature('trellance_segmentation');
    const hashubspotSegments = await getUserFeature('hubspot_segmentation');
    await setUserHasTrellanceSegmentationPermission(hastrellanceSegments);
    await setUserHasHubSpotSegmentationPermission(hashubspotSegments);
    setUserNudgeAssist(HasNudgeAssist)
  }, []);


    useEffect(async () => {
    const HasNudgeAssist_tt = await UserService.getFeatureFlagInfoWithUserId(getAllFeatureFlagsQuery, user.id, 'nudge_assist');
    setNudgeAssistToolTipText(HasNudgeAssist_tt.tooltip);
    setNudgeAssistUrl(HasNudgeAssist_tt.tooltipUrl);

    }, [user.id]);

   useEffect(() => {
    async function fetchCampaign() {
      try {
        const campaign = await CampaignService.getCampaign(
          getCampaignQuery,
          organizationId,
          campaignId,
        );

        setTemplateId(campaign.templateId);

        const formattedStartDate = campaign.startDate
          ? moment(campaign.startDate).format(INPUT_DATE_FORMAT)
          : null;

        const formattedEndDate = campaign.endDate
          ? moment(campaign.endDate).format(INPUT_DATE_FORMAT)
          : null;

        setShowAnalytics(!(!initialValues.campaignStatus && !campaign.status));

        setIsLoading(false);
        setCampaignDetails(campaign);
        const newInitialValues = {
          name: campaign.name,
          description: campaign.description,
          inclSegmentId: campaign.inclSegmentId
            ? (getCampaignUserSegmentFromList(userSegmentList, Number(campaign.inclSegmentId)) ||
                  getCampaignTrellanceSegmentFromList(trellanceSegmentList, Number(campaign.inclSegmentId)) ||
                  getCampaignHubspotSegmentFromList(hubSpotSegmentList, Number(campaign.inclSegmentId)))
            : null,
          tapBehavior: campaign.tapBehavior ? 1 : 0,
          updatedBy: user.id,
          startDate: formattedStartDate,
          endDate: formattedEndDate,
          status: initialValues.campaignStatus || campaign.status,
          showAnalytics,
          archived: campaign.archived,
          campaignCategory: campaign.campaignCategoryId ? campaignCategoryOptions.find(
              ({ value }) => value === campaign.campaignCategoryId) : null
        };
        const campaignMessages = await CampaignService.getCampaignNudges(
          getCampaignMessagesQuery, organizationId, campaignId,
        );

        if (isUpdate) {
          campaignMessages.hits.length ? setContainsNudges(true) : setContainsNudges(false);
        }

        handleRadioButtonChange(Number(campaign.tapBehavior));
        setInitialValues({ ...newInitialValues });
      } catch (e) {
        Analytics.trackApplicationError(e.message, FILE_NAME, 'fetchCampaign');
        notification.alert(e.message, 'danger');
      }
    }

    async function fetchUserSegments() {
      try {
        const userSearchParams = {
          segmentType: 'user-file',
        };
        const res = await SegmentService.getSegments(getSegmentsQuery, userSearchParams);
        setUserSegmentList(getUserSegmentDropdownOptions(res.hits));
      } catch (err) {
        Analytics.trackApplicationError(err.message, FILE_NAME, 'fetchUserSegments');
        notification.alert('Failed to fetch user segments', 'danger');
      }
    }

    async function fetchTrellanceSegments() {
      try {
        const trellanceSearchParams = {
          segmentType: 'trellance',
        };
        const res = await SegmentService.getSegments(getSegmentsQuery, trellanceSearchParams);
        setTrellanceSegmentList(getTrellanceSegmentDropdownOptions(res.hits));
      } catch (err) {
        Analytics.trackApplicationError(err.message, FILE_NAME, 'fetchTrellanceSegments');
        notification.alert('Failed to fetch Trellance segments', 'danger');
      }
    }


    async function fetchHubSpotSegments() {
      try {
        const hubpostSearchParams = {
          segmentType: 'hubspot',
        };
        const res = await SegmentService.getSegments(getSegmentsQuery, hubpostSearchParams);
        setHubSpotSegmentList(getHubSpotSegmentDropdownOptions(res.hits));
      } catch (err) {
        Analytics.trackApplicationError(err.message, FILE_NAME, 'fetchHubSpotSegments');
        notification.alert('Failed to fetch Hubspot segments', 'danger');
      }
    }

    const fetchSegmentsForCampaign = async () => {
      try {
        await fetchUserSegments();
        await fetchTrellanceSegments();
        await fetchHubSpotSegments();
        await fetchCampaign();
      } catch (err) {
        console.log(err);
      }
    };

    if (isUpdate && Object.keys(initialValues).length === 0) fetchSegmentsForCampaign();
  }, [
    getCampaignQuery,
    isUpdate,
    initialValues,
    setInitialValues,
    userSegmentList,
    trellanceSegmentList,
    hubSpotSegmentList,
    containsNudges,
    activatedCampaign,
  ]);

  const handleSubmitForm = async () => {
    // Formik tip, helpful for debugging
    // validationSchema().validate(formik.values)
    //   .catch(function(e) {
    //     console.log(e);
    //   });
    const formikValues = formik.values;
    if (!formikValues.startDate && initialValues.status === 1) {
      setDateValid('Since this campaign is active, you must enter a start date!');
      return;
    }
    formik.submitForm();
  };

  const handleSubmitTemplate = async () => {
    try {
      const temporaryFlagCheck = campaignDetails.temporaryFlag;
      setTemporaryFlag(temporaryFlagCheck);
      setShowTemplateCreatorModal(true);
    } catch (err) {
      console.log(err);
    }
  };

  const handleUpdateTemplate = async () => {
    try {
      setUpdateTemplate(true);
      handleSubmitForm();
    } catch (err) {
      console.log(err);
    }
  };

  const handleCancelCreateOrEditCampaign = async () => {
    if (!formik.dirty) {
      history.goBack();
      return;
    }
    const res = await notification.fullScreenDialogCardConfirm(
      '',
      'Are you sure you want to lose changes to this campaign?',
      '',
      'Lose Changes',
    );
    if (res) {
      history.goBack();
    }
  };
  const currentCampaignName = initialValues.name;

  const getAnalyticsModalParams = () => {
    const downloadAnalyticsParams = {
      ENVIRONMENT,
      FILE_NAME,
      organizationId,
      notification,
      getNudgesReceivedQuery,
      getNudgesTappedQuery,
      getNudgesHeadersQuery,
      NUMBER_OF_DAYS_TO_SHOW_ON_DOWNLOAD,
    };
    if (currentCampaignName) {
      return {
        id: campaignId || '',
        name: currentCampaignName,
        analyticsParams: downloadAnalyticsParams,
        type: 'Campaign',
      };
    }
    return { id: '', name: '' };
  };
  const isDisabled = () => {
    const { archived } = initialValues;
    if (userHasCampaignsFeatureReadOnly) {
      return true;
    }
    return !!archived;
  };

  const getTitle = () => {
    if (initialValues.archived) {
      return 'View this Campaign';
    }
    if (initialValues.status === 1 || activatedCampaign) {
      return 'Manage this Campaign';
    }
    if (isUpdate && !campaignDetails.temporaryFlag) {
      return 'Edit this Campaign';
    }
    return 'Create a Campaign';
  };

  const getSubtitle2 = () => {
    if (!isUpdate) {
      return "Create the overall structure here first, and we'll launch it on a subsequent screen";
    }
    return null;
  };

  const getDescriptionTitle = () => 'Give your Campaign a description';

  const setSubmitButtonText = () => {
    if (!isUpdate || campaignDetails.temporaryFlag) {
      return 'Create Campaign';
    }
    return 'Update Campaign';
  };

  const setCancelButtonText = () => {
    if (isDisabled()) {
      return 'Close';
    }
    return 'Cancel';
  };

  const handleActivateOrDeactivateCampaign = async () => {
    /**
     * campaign pending: status = 0
     * campaign active:  status = 1
     * campaign paused:  status = 2
     */
    try {
      if (!containsNudges && initialValues.status !== 1) {
        setValidationWarning(true);
        return;
      }
      const formikValues = formik.values;
      if (!formikValues.startDate && !formikValues.endDate && initialValues.status !== 1) {
        setDateValid('You must enter a start date to activate a campaign!');
        return;
      }
      if (formikValues.startDate && dateValid) {
        setDateValid(false);
      }
      if (formikValues.endDate && !formikValues.startDate && initialValues.status !== 1) {
        setDateValid('You must enter a start date to activate a campaign!');
        return;
      }
      if (formikValues.inclSegmentId === null && initialValues.status !== 1) {
        setDateValid('You must select a segment to activate a campaign!');
        return;
      }
      if (formik.values.campaignCategory && categoryWarning) {
        setCategoryWarning(false);
      }
      if (formik.values.campaignCategory === null && initialValues.status !== 1) {
        setCategoryWarning('You must select a category to activate a campaign!');
        return;
      }

      const errors = initialValues.status !== 1 ? await activationValidationSchema().validate(formik.values).catch((err) => {
        console.log(err);
        return err;
      }) : await validationSchema().validate(formik.values).catch((err) => {
        console.log(err);
        return err;
      });
      if (initialValues.status === 1) {
        if (!formikValues.startDate) {
          setDateValid('Since this campaign is active, you must enter a start date!');
          return;
        }
        if (!errors.errors) {
          const res = await notification.fullScreenDialogCardConfirm(
            '',
            'This will pause your campaign and stop sending nudges until it is activated again.',
            '',
            'Pause Campaign',
          );
          if (res) {
            formik.setFieldValue('status', 2);
            handleSubmitForm();
          }
        } else {
          handleSubmitForm();
        }
      }
      if (initialValues.status === 2 || (initialValues.status === null && containsNudges)) {
        if (!formikValues.startDate) {
          setDateValid('You must enter a start date to activate a campaign');
          return;
        }
        if (!errors.errors) {
          const res = await notification.fullScreenDialogCardConfirm(
            '',
            'This will activate your campaign and begin sending nudges as scheduled.',
            '',
            'Activate Campaign',
          );
          if (res) {
            formik.setFieldValue('status', 1);
            handleSubmitForm();
          }
        } else {
          handleSubmitForm();
        }
      }
    } catch (err) {
      notification.alert(err.message, 'danger');
      Analytics.trackApplicationError(err.message, FILE_NAME, 'onSubmit');
    }
  };
  if (isUpdate && Object.keys(initialValues).length === 0) {
    return (
      <InitialLoader />
    );
  }
  const radioButtonsInput = () => radioOptions.map((option) => (
    <CustomRadioButton
      key={option.value}
      name="tapBehavior"
      value={option.value}
      onChange={(value) => handleRadioButtonChange(value)}
      option={option}
      disabled={isDisabled()}
    />
  ));

  return (
    <div className={styles.root}>
      <form onSubmit={formik.handleSubmit}>
        <Row>
          <Col style={{ flex: 7 }}>
            <Row className={styles.section}>
              <CustomSection header>
                <PageHeader
                  title={getTitle()}
                />
                <Col xs={12} sm="auto">
                  <Container fluid>
                    {isUpdate && showAnalytics ? (
                      <Row className="justify-content-md-end">
                        <Larky.Button
                          size="lg"
                          onClick={showAnalyticsPopup}
                        >
                          Show Full Analytics
                        </Larky.Button>
                      </Row>
                    ) : null}
                  </Container>
                </Col>
              </CustomSection>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col style={{ flex: 7 }}>
            {/* NAME CAMPAIGN */}
            <Row className={styles.section}>
              <CustomSection title="Organize your campaign">
                <div className={styles.section}>
                  <CustomInput
                    type="text"
                    name="name"
                    title="Name your Campaign"
                    placeholder="e.g. New Car Loan Campaign (required)"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    error={formik.errors.name}
                    disabled={isDisabled()}
                  />

                  <CustomInput
                    type="textarea"
                    name="description"
                    title={getDescriptionTitle()}
                    placeholder="e.g. 6-week cadence of nudges promoting new car loans (optional)"
                    value={formik.values.description}
                    onChange={formik.handleChange}
                    error={formik.errors.description}
                    disabled={isDisabled()}
                  />
                  {isUpdate && !isLoading && (
                    <>
                      <CustomInput
                        type="select"
                        name="selectSegment"
                        title="Select a category for your campaign"
                        description="Categories help organize your campaigns and give a big picture view for analytics"
                        placeholder="Select..."
                        onChange={(campaignCategory) => {
                          formik.setFieldValue('campaignCategory', campaignCategory)
                        }}
                        value={formik.values.campaignCategory}
                        error={formik.errors.campaignCategory}
                        options={campaignCategoryOptions}
                        disabled={isDisabled()}
                      />
                      {categoryWarning ? (<p className={styles.alert}>{categoryWarning}</p>) : (null)}
                    </>
                  )}
                  <div className={styles.horizontal}>
                    <Col className={styles.fourth}>
                      <CustomInput
                        type="datetime"
                        name="startDate"
                        title="Start date"
                        placeholder="Select..."
                        dateFormat={INPUT_DATE_FORMAT}
                        onChange={(date) => {
                          typeof (date) === 'string' ? formik.setFieldValue('startDate', null)
                            : formik.setFieldValue('startDate', date);
                        }}
                        value={moment(formik.values.startDate)}
                        error={formik.errors.startDate}
                        disabled={isDisabled()}
                      />
                    </Col>
                    <Col className={styles.fourth}>
                      <CustomInput
                        type="datetime"
                        name="endDate"
                        title="End date"
                        placeholder="Select..."
                        dateFormat={INPUT_DATE_FORMAT}
                        onChange={(date) => {
                          typeof (date) === 'string' ? formik.setFieldValue('endDate', null)
                            : formik.setFieldValue('endDate', date);
                        }}
                        value={moment(formik.values.endDate)}
                        error={formik.errors.endDate}
                        disabled={isDisabled()}
                      />
                    </Col>
                    {campaignId && !campaignDetails.temporaryFlag ? (
                      <Col className={styles.activate}>
                        {validationWarning && !containsNudges ? (
                          <p className={styles.alert}>
                            Please add at least one nudge before
                            activating your campaign!
                          </p>
                        ) : null}
                        {dateValid ? (<p className={styles.alert}>{dateValid}</p>) : (null)}
                        <Larky.Button
                          size="lg"
                          onClick={() => {
                            handleActivateOrDeactivateCampaign();
                          }}
                          disabled={formik.isSubmitting || isDisabled()}
                        >
                          {(containsNudges && initialValues.status === 1) || initialValues.status === 1 || activatedCampaign ? 'Pause Campaign' : 'Activate Campaign'}
                        </Larky.Button>
                      </Col>
                    )
                      : null}
                  </div>
                </div>
                <div className={styles.horizontal} />
              </CustomSection>
            </Row>

            {!isUpdate && (
            <Row>
          <Col style={{ flex: 7 }}>
            <Row className={!userHasNudgeAssist ? styles['disabled-button'] : styles.section}>
              <CustomSection>
                <h2 className="title-with-image">
                <img src={ButtonImage} alt="Button Image" className="title-image"/>
                  {' '} nudge Assist
                  { !userHasNudgeAssist ? (
                  <ToolTipIcon classNameIcon="tooltip-icon-cp"
                               className="tool-tip-container-nudge"
                               url={NudgeAssistUrl}
                               toolTipMessage="This enhanced functionality is included with nudge® Engage.Click to learn more!"
                               flag={1}>
                  </ToolTipIcon>) : null }
                </h2>
            <h6 style={{marginLeft: 3}}>Provide a short topic for your campaign and nudge Assist will automatically create several nudges for you!</h6>
                <CustomInput
                    type="text"
                    name="nudgeassist"
                    title="Enter a topic for your campaign"
                    placeholder="e.g. New Car Loan"
                    value={formik.values.nudgeassist}
                    onChange={formik.handleChange}
                    errors={formik.errors.nudgeassist}
                    disabled={!userHasNudgeAssist ? true : false}
                  />
              {nudgeassistWarning ? (<p className={styles.alert}>{nudgeassistWarning}</p>) : (null)}
            </CustomSection>
            </Row>
            </Col>
            </Row>
            )}

            {/* SEGMENT INFORMATION */}
            {isUpdate && !isLoading && (
            <Row className={styles.section}>
              <CustomSection
                title="Recipients for the campaign"
              >
                <h4>
                  Campaigns get delivered to all members in a given segment (or group of
                  segments)
                </h4>
                <h4>
                  {' '}
                  If members get added to the segment later, they will enter into the campaign as
                  well
                </h4>
                <CustomInput
                  type="select"
                  name="selectSegment"
                  title="Select the segment(s) of recipients for this campaign"
                  placeholder="Select..."
                  onChange={(segment) => formik.setFieldValue('inclSegmentId', segment)}
                  value={formik.values.inclSegmentId}
                  error={formik.errors.inclSegmentId}
                  options={[...(userHasHubspotSegmentationPermission && hubSpotSegmentList.length > 0
                            ? [{ label: 'HUBSPOT', options: hubSpotSegmentList }]
                            : []),...(userHasTrellanceSegmentationPermission && trellanceSegmentList.length > 0
                            ? [{ label: 'TRELLANCE', options: trellanceSegmentList }]
                            : []),
                        { label: 'Segments', options: userSegmentList }
                        ]}
                  disabled={isDisabled()}
                />
                <h4 style={{ marginTop: '3rem' }}>
                  You can choose to have recipients who tap on a nudge
                  to automatically drop out of further messages in the campaign
                </h4>
                <h5 style={{ font: 'bold 25px/24px Arial MT, Arial' }}>
                  Choose what happens when a
                  recipient taps a nudge
                </h5>
                {radioButtonsInput()}
              </CustomSection>
            </Row>
            )}
            {/* CAMPAIGN NUDGES */}
            {isUpdate && !isLoading && (
            <Row style={{ margin: '48px 0 24px 0' }}>
              <CustomSection title="Campaign nudges">
                <h4>
                  A campaign consists of one or more nudges which will be delivered on a cadence
                  you control
                </h4>
                <CampaignMessages
                  organizationId={organizationId}
                  campaignId={campaignId}
                  nudgeAdditionDisabled={initialValues.status === 1 || activatedCampaign || isDisabled()}
                  nudgeReorderingDisabled={initialValues.status === 1 || activatedCampaign || isDisabled()}
                  setContainsNudges={setContainsNudges}
                  status={formik.values.status}
                  showAnalytics={showAnalytics}
                  readOnly={isDisabled()}
                  handleShowCampaignNudgeAnalytics={showAnalyticsNudgePopup}
                  campaignAnalyticsInDropDown={campaignDetails && campaignDetails.statusDescription !== 'Pending'}
                  readOnlyUser={userHasCampaignsFeatureReadOnly}
                  isArchived={initialValues && initialValues.archived}
                />
              </CustomSection>
            </Row>
            )}
            {/* SAVE BUTTONS */}
            <Row
              className={styles.horizontal}
              style={{ justifyContent: 'center', paddingTop: '2em', paddingBottom: '1.5em' }}
            >
              {isTemplateCreator && isUpdate ? (
                <Col xs={12} md="auto">
                  <CustomButton
                    variant="contained"
                    size="lg"
                    text="Create new Template"
                    onClick={() => {
                      handleSubmitTemplate();
                    }}
                    disabled={formik.isSubmitting}
                  />
                </Col>
              ) : null}
              {isTemplateCreator && campaignDetails && campaignDetails.templateId && isUpdate && campaignDetails.temporaryFlag ? (
                <Col xs={12} md="auto">
                  <CustomButton
                    variant="contained"
                    size="lg"
                    text="Update template"
                    onClick={() => {
                      handleUpdateTemplate();
                    }}
                    disabled={formik.isSubmitting}
                  />
                </Col>
              ) : null}
              <Col xs={12} md="auto">
                <CustomButton
                  variant="outlined"
                  size="lg"
                  text={setCancelButtonText()}
                  onClick={() => handleCancelCreateOrEditCampaign()}
                />
              </Col>
              {!isDisabled() && (
              <Col xs={12} md="auto">
                <CustomButton
                  variant="contained"
                  size="lg"
                  text={setSubmitButtonText()}
                  onClick={() => {
                    handleSubmitForm();
                  }}
                  disabled={formik.isSubmitting}
                  isLoading={formik.isSubmitting}
                />
              </Col>
              )}
            </Row>
          </Col>
        </Row>
      </form>
      {isUpdate && (
        <AnalyticsModal
          organizationId={organizationId}
          show={showModal}
          callback={hideModalCallback}
          campaignId={getAnalyticsModalParams().id}
          downloadAnalyticsParams={getAnalyticsModalParams()}
          title="Campaign Analytics"
          text={currentCampaignName}
        />
      )}
      {selectedCampaignNudge && (
        <AnalyticsModal
          organizationId={organizationId}
          show={selectedCampaignNudge}
          callback={hideModalCallbackNudge}
          nudgeId={selectedCampaignNudge ? selectedCampaignNudge.messageId : null}
          campaignId={campaignId}
          title="Campaign Nudge Analytics"
          text={selectedCampaignNudge ? selectedCampaignNudge.name : null}
          disableAnalyticsExport
        />
      )}
      {showTemplateCreatorModal && (
        <CampaignTemplateEditModal
          templateInfo={{}}
          setShow={setShowTemplateCreatorModal}
          show={showTemplateCreatorModal}
          callback={setShowTemplateCreatorModal}
          isCreate
          campaignId={getAnalyticsModalParams().id}
          updatedBy={user.id}
          saveCallback={handleSubmitForm}
          temporaryFlag={temporaryFlag}
        />
      )}
    </div>
  );
}

CreateOrUpdateCampaign.propTypes = {
  organizationId: PropTypes.number,
};

CreateOrUpdateCampaign.defaultProps = {
  organizationId: null,
};

export default CreateOrUpdateCampaign;
