// Dependencies
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useMutation, useQuery } from '@apollo/client';

// Components
import CustomTable from 'components/CustomTable/CustomTable';
import { useNotification } from 'components/Notification/Notification';

// Services
import SegmentService from 'services/SegmentService';

// GraphQL
import { CHECK_WHO_IS_USING_SEGMENT, REFRESH_USER_SEGMENT, SEARCH_SEGMENTS } from 'graphql/queries/segments';
import { DELETE_SEGMENT_MUTATION } from 'graphql/mutations/segments';

// Utils
import { deleteSegmentRow, getUserFeature, refreshRow } from 'Utils';

// Constants
import { ROUTES } from 'Constants';

// Context
import { Col, Container, Row } from 'react-bootstrap';
import Larky from 'components/Larky';

// Styles
import { useHistory } from 'react-router-dom';
import SegmentRow from './components/SegmentRow/SegmentRow';

import { useInitialFocus } from '../../hooks/useInitialFocus';
import PageHeader from '../../components/PageHeader/PageHeader';

function Segments({ organizationId }) {
  const notification = useNotification();
  const history = useHistory();
  useInitialFocus();

  const [deleteSegmentMutation] = useMutation(DELETE_SEGMENT_MUTATION);
  const checkWhoIsUsingSegmentQuery = useQuery(CHECK_WHO_IS_USING_SEGMENT, { skip: true });
  const searchSegmentQuery = useQuery(SEARCH_SEGMENTS, { skip: true });
  const refreshUserSegmentQuery = useQuery(REFRESH_USER_SEGMENT, { skip: true });
  const [trellanceSegment, setTrellanceSegment] = useState(false);
  const [hubspotSegment, setHubspotSegment] = useState(false);
  const [hasSegmentEditPermission, setHasSegmentEditPermission] = useState(null);


  useEffect(async () => {
    const userHasUserSegmentEditPermission = await getUserFeature('user_segmentation', 2);
    const userHasSystemSegmentEditPermission = await getUserFeature('system_segmentation', 2);
    const userHasTrellanceSegment = await getUserFeature('trellance_segmentation');
    const userHasHubspotSegment = await getUserFeature('hubspot_segmentation');
    setTrellanceSegment(userHasTrellanceSegment);
    setHubspotSegment(userHasHubspotSegment);
    setHasSegmentEditPermission(userHasUserSegmentEditPermission || userHasSystemSegmentEditPermission);

  }, []);

  const getData = async (orderBy, sortOrder, start, params) => {
    let segment_type = 'user-file-trellance-hubspot'
    if (!(trellanceSegment || hubspotSegment)) {
      segment_type = 'user-file';
    }
    Object.assign(params, { segmentType: segment_type});
    // FYI for segments, there's no need to include the organization_id, it's handled by the backend
    return SegmentService.searchSegments(
      searchSegmentQuery,
      orderBy,
      sortOrder,
      start,
      params,
    );
  };
  return (
    <Container fluid>
      <Row>
        <Col className="col-12 p-sm-5 p-3">
          <Container fluid>
            <Row>
              <Col>
                <Container fluid>
                  <PageHeader
                    title="My Segments"
                    subtitle="View all of your segments"
                    hasEditPermission={hasSegmentEditPermission}
                  />
                  <CustomTable
                    getData={getData}
                    customRow={(data, handleSelect, updateCallback) => (
                      <SegmentRow
                        key={data.id}
                        data={data}
                        handleSelect={handleSelect}
                        handleDeleteRow={() => deleteSegmentRow(
                          SegmentService,
                          notification,
                          deleteSegmentMutation,
                          checkWhoIsUsingSegmentQuery,
                          data.id,
                          updateCallback,
                          'Segment',
                        )}
                        handleRefreshRow={() => {
                          // for now refresh is disabled for File-based segments
                          if (data.segmentType === 'file') {
                            return () => {};
                          }
                          return refreshRow(SegmentService,
                            notification,
                            refreshUserSegmentQuery,
                            data.id,
                            updateCallback,
                            'Segment');
                        }}
                        hasEditPermission={hasSegmentEditPermission}
                      />
                    )}

                    // columns={['Status', 'Type', 'Name', 'Members', 'Created', 'Refreshed', 'In Use']}
                    columns={['Type', 'Name', 'Members', 'Created', 'Refreshed']}
                    searchKeys={['filterCreatedAfter', 'filterKeyword', 'filterCustomType']}
                    enabledFilters={['searchQuery', 'createdAfterFilter', 'segmentTypeFilter']}
                    filterAttrs={['created', 'name', 'title', 'customType']}
                    isFilterable
                    isPaginable
                  />
                </Container>
              </Col>
            </Row>
          </Container>
        </Col>
      </Row>
    </Container>
  );
}
Segments.propTypes = {
  organizationId: PropTypes.number,
};

Segments.defaultProps = {
  organizationId: null,
};

export default Segments;
