import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Row } from 'react-bootstrap';
import styles from '../CreateOrUpdateSegment/CreateOrUpdateSegment.module.scss';

function ImportSegmentFileSummary({ segmentSummary }) {
  const {
    totalEntries, uniqueEntries, matchedEntries, unmatchedEntries,
  } = segmentSummary;
  const totaEntriesText = () => {
    if (totalEntries === 1) {
      return `${totalEntries} total entry`;
    }
    return `${totalEntries} total entries`;
  };
  const matchedEntriesText = () => {
    if (matchedEntries === 1) {
      return `${matchedEntries} entry matches members found in the nudge system and will be included in your segment`;
    }
    return `${matchedEntries} entries match members found in the nudge system and will be included in your segment`;
  };
  const unmatchedEntriesText = () => {
    if (unmatchedEntries === 1) {
      return `${unmatchedEntries} entry don't match members found in the nudge system and will be ignored`;
    }
    return `${unmatchedEntries} entries don't match members found in the nudge system and will be ignored`;
  };

  return (
    <div className={styles.root}>
      <Row>
        <p>The file you selected contains the following:</p>
        <br />
        <ul>
          <li>{totaEntriesText()}</li>
          <li>{matchedEntriesText()}</li>
          <li>{unmatchedEntriesText()}</li>
        </ul>
      </Row>
    </div>
  );
}

ImportSegmentFileSummary.propTypes = {
  segmentSummary: PropTypes.instanceOf(Object),
};

ImportSegmentFileSummary.defaultProps = {
  segmentSummary: null,
};

export default ImportSegmentFileSummary;
