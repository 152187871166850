// Dependencies
import moment from 'moment';
import Analytics from 'libs/Analytics';

// Constants
import {
  CONDITION_TYPES,
  GEOLOCATION,
  INPUT_TIME_FORMATS,
  NUDGE_STATUSES,
  OUTPUT_TIME_FORMAT,
  ROLES,
  TIME_BASED,
} from 'Constants';

const FILE_NAME = 'Utils.jsx';

export const ALL_POSSIBLE_RECIPIENTS_OPTION = { value: 0, label: 'All possible recipients' };

export const getUserSegmentFromList = (userSegmentList, segmentId) => {
  // ALL_POSSIBLE_RECIPIENTS_OPTION is a placeholder
  // console.log('userSegmentList in utils='+userSegmentList)
  // console.log(segmentId)
  if (!segmentId) return ALL_POSSIBLE_RECIPIENTS_OPTION;
  const userSegment = userSegmentList.find(
    (elem) => Number(elem.value) === segmentId,
  );

  return userSegment;
};


export const getTrellanceSegmentFromList = (trellanceSegmentList, segmentId) => {
  // ALL_POSSIBLE_RECIPIENTS_OPTION is a placeholder
  if (!segmentId) return ALL_POSSIBLE_RECIPIENTS_OPTION;
  const trellanceSegment = trellanceSegmentList.find(
    (elem) => Number(elem.value) === segmentId,
  );

  return trellanceSegment;
};


export const getHubSpotSegmentFromList = (hubSpotSegmentList, segmentId) => {
  // ALL_POSSIBLE_RECIPIENTS_OPTION is a placeholder
  if (!segmentId) return ALL_POSSIBLE_RECIPIENTS_OPTION;
  const hubspotSegment = hubSpotSegmentList.find(
    (elem) => Number(elem.value) === segmentId,
  );

  return hubspotSegment;
};


export const getCampaignUserSegmentFromList = (userSegmentList, segmentId) => {
  if (!segmentId) return null;
  const userSegment = userSegmentList.find(
    (elem) => Number(elem.value) === segmentId,
  );

  return userSegment;
};


export const getCampaignTrellanceSegmentFromList = (trellanceSegmentList, segmentId) => {
  if (!segmentId) return null;
  const trellanceSegment = trellanceSegmentList.find(
    (elem) => Number(elem.value) === segmentId,
  );

  return trellanceSegment;
};


export const getCampaignHubspotSegmentFromList = (hubSpotSegmentList, segmentId) => {
  if (!segmentId) return null;
  const hubspotSegment = hubSpotSegmentList.find(
    (elem) => Number(elem.value) === segmentId,
  );

  return hubspotSegment;
};


export const getOrgFromDropdownOptions = (organization, orgOptions) => {
  if (orgOptions.length && organization) {
    return orgOptions.find((elem) => elem.label === organization.name);
  }
  return null;
};
export const getUserSegmentDropdownOptions = (userSegments) => {
  // console.log(userSegments)
  if (userSegments.length) {
    const mapSegment = (segment) => ({
      value: parseInt(segment.id),
      label: segment.segmentName,
    });
    // console.log(userSegments.map(mapSegment))
    return userSegments.map(mapSegment);
  }
  return [];
};


export const getTrellanceSegmentDropdownOptions = (trellanceSegments) => {
  if (trellanceSegments.length) {
    const mapSegment = (segment) => ({
      value: parseInt(segment.id),
      label: segment.segmentName,
    });
    return trellanceSegments.map(mapSegment);
  }
  return [];
};

export const getHubSpotSegmentDropdownOptions = (hubSpotSegments) => {
  if (hubSpotSegments.length) {
    const mapSegment = (segment) => ({
      value: parseInt(segment.id),
      label: segment.segmentName,
    });
    return hubSpotSegments.map(mapSegment);
  }
  return [];
};

export const getDateAndTimeFromConditions = (conditions) => {
  const inputDateFormat = 'MM/DD/YY';
  const inputTimeFormat = 'hh:mm A';
  const noDateTimeValue = { date: null, time: null };
  if (!conditions || conditions.length === 0) {
    return noDateTimeValue;
  }
  const actionId = CONDITION_TYPES.SCHEDULED_ACTION.ID;
  const condition = conditions.find(
    (elem) => Number(elem.conditionType.id) === actionId,
  );
  if (condition) {
    if (condition.dateTime === null) return noDateTimeValue;
    const scheduledDate = moment.utc(condition.dateTime);
    if (!scheduledDate.isValid()) return noDateTimeValue;
    return {
      date: scheduledDate.local().format(inputDateFormat),
      time: scheduledDate.local().format(inputTimeFormat),
    };
  }
  return noDateTimeValue;
};

export const getLocationsFromConditions = (conditions) => {
  const locations = [];
  conditions.forEach((condition) => {
    if (Number(condition.conditionType.id) === CONDITION_TYPES.GEOFENCE.ID) {
      locations.push(condition.location ? condition.location.name : null);
    }
  });
  return locations;
};

/**
 * Print pretty date form datetime
 * @param {Date} datetime
 */
// export const printPrettyDateFromDatetime = (datetime) => {
//
//   const date = (new Date(datetime.replace(/-/g, '/')));
//   const prettyDate = moment(date).format('MM/DD/YYYY');
//   return prettyDate;
// };

export const printPrettyDateFromDatetime = (datetime) => {
  const formattedDate = moment.utc(datetime).format('MM/DD/YYYY');
  return formattedDate;
};



/**
 * Build query params URL
 * @param {Array<{key: string, value: string | number | null }>} args
 */
export const buildQueryParamsURL = (args = []) => {
  const queryParams = new URLSearchParams();

  args
    .filter((arg) => arg.value !== null && (arg.value.length > 0 || arg.value > 0))
    .forEach((arg) => {
      queryParams.append(arg.key, arg.value);
    });

  return `?${queryParams.toString()}`;
};

function createTimeString(momentDate) {
  return moment(momentDate, INPUT_TIME_FORMATS).format(OUTPUT_TIME_FORMAT);
}

export const formatTimeWithDefault = (time, defaultTime, allDay) => {
  if (time === null || allDay) {
    return defaultTime.format(OUTPUT_TIME_FORMAT);
  }
  return time ? createTimeString(time) : defaultTime.format(OUTPUT_TIME_FORMAT);
};

/**
 * Validate if a string is JSON
 * @param {String} str
 */
export function isJSON(str) {
  try {
    const result = JSON.parse(str);
    if (result == null || typeof result !== 'object') {
      return false;
    }
  } catch (e) {
    return false;
  }
  return true;
}

/**
 * Archive Row
 * @param {Object} service
 * @param {Object} notification
 * @param {{id, archived}} params
 * @param {Function} updateCallback
 * @param {String} tableName
 */
export const archiveRow = async (
  Service,
  notification,
  mutation,
  { id, archived, statusDescription },
  updateCallback,
  tableName = 'Row',
) => {
  try {
    const archivedStatus = !!(statusDescription === 'Archived' || archived);
    await Service.archive(mutation, id, !archivedStatus);
    updateCallback();
    notification.alert(`${tableName} archived successfully`, 'success');
  } catch (err) {
    Analytics.trackApplicationError(
      err.message,
      FILE_NAME,
      `${tableName} - archiveRow`,
    );
    notification.alert(err.message, 'danger');
  }
};

/**
 * Delete Row
 * @param {Object} service
 * @param {Object} notification
 * @param {String} id
 * @param {Function} updateCallback
 * @param {String} tableName
 */
export const deleteRow = async (
  Service,
  notification,
  mutation,
  id,
  updateCallback,
  tableName = 'Row',
) => {
  try {
    const res = await Service.delete(mutation, id);
    if (res) {
      updateCallback();
      notification.alert(`${tableName} deleted successfully`, 'success');
    }
  } catch (err) {
    Analytics.trackApplicationError(
      err.message,
      FILE_NAME,
      `${tableName} - deleteRow`,
    );
    notification.alert(err.message, 'danger');
  }
};

/**
 * Delete Row
 * @param {Object} service
 * @param {Object} notification
 * @param {String} id
 * @param {Function} updateCallback
 * @param {String} tableName
 */
export const deleteSegmentRow = async (
  Service,
  notification,
  mutation,
  query,
  id,
  updateCallback,
  tableName = 'Row',
) => {
  try {
    const res = await Service.delete(mutation, query, id);
    if (res) {
      updateCallback();
      notification.alert(`${tableName} deleted successfully`, 'success');
    }
  } catch (err) {
    Analytics.trackApplicationError(
      err.message,
      FILE_NAME,
      `${tableName} - deleteRow`,
    );
    notification.alert(err.message, 'danger');
  }
};

/**
 * Delete Camapign Row
 * @param {Object} service
 * @param {Object} notification
 * @param {String} id
 * @param {Array} campaignNudges
 * @param {String} campaignId
 * @param {fucntion} setOrderOfNudges (callback for setting correct order of nudges)
 * @param {fucntion} setRefetchMessages (callback for refreshing nudges after delete)
 * @param {string} messageName
 */
export const deleteCampaignRow = async (
  Service,
  notification,
  mutation,
  nudgeId,
  campaignNudges,
  campaignId,
  setOrderOfNudges,
  setRefetchMessages,
  messageName,
  // tableName = 'Row',
) => {
  try {
    const res = await Service.deleteCampaignNudge(
      mutation,
      messageName,
      campaignId,
      nudgeId,
      campaignNudges,
      setOrderOfNudges,
    );

    if (res) {
      notification.alert('Nudge deleted successfully', 'success');

      setRefetchMessages(true);
      return;
    }
  } catch (err) {
    notification.alert(err.message, 'danger');
  }
};

/**
 * Refresh Row
 * @param {Object} service
 * @param {Object} notification
 * @param {Object} query
 * @param {String} id
 * @param {Function} updateCallback
 * @param {String} tableName
 */
export const refreshRow = async (
  Service,
  notification,
  query,
  id,
  updateCallback,
  tableName = 'Row',
) => {
  try {
    const res = await Service.refreshUserSegment(query, id);

    if (res) {
      updateCallback();
      notification.alert(
        `${tableName} refreshed successfully`,
        'success',
      );
    }
  } catch (err) {
    notification.alert(err.message, 'danger');
  }
};

/**
 *
 * @param {Object} service
 * @param {Object} notification
 * @param {string[]} ids
 * @param {Object} updateCallback
 * @param {string} tableName
 */
export const archiveManyRows = async (
  Service,
  notification,
  mutation,
  ids,
  updateCallback,
  tableName = 'Rows',
  archived = true,
) => {
  try {
    const res = await Service.archiveMany(mutation, ids, archived);

    if (res) {
      updateCallback();
      notification.alert(
        `${tableName} ${archived ? 'archived' : 'unarchived'} succesfully`,
        'success',
      );
    }
  } catch (err) {
    notification.alert(err.message, 'danger');
  }
};

/**
 *
 * @param {Object} service
 * @param {Object} notification
 * @param {string[]} ids
 * @param {Object} updateCallback
 * @param {string} tableName
 */
export const deleteManyRows = async (
  Service,
  notification,
  mutation,
  ids,
  updateCallback,
  tableName = 'Rows',
) => {
  try {
    const res = await Service.deleteMany(mutation, ids);

    if (res) {
      updateCallback();
      notification.alert(`${tableName} deleted succesfully`, 'success');
    }
  } catch (err) {
    notification.alert(err.message, 'danger');
  }
};

/**
 * File to url
 * @param {File} file
 */
export const fileToUrl = (file) => new Promise((resolve, reject) => {
  if (typeof file === 'string') resolve(file);
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result);
  reader.onerror = (error) => reject(error);
});

/**
 * Remove DOM element
 * @param {import('react').DOMElement} el
 */
export function removeDOMElement(el) {
  document.body.removeChild(el);
  document.querySelector('body').style.overflow = null;
}

/**
 * Parse role
 * @param {String} role
 */
export function parseRole(role) {
  const result = ROLES.find(({ key }) => key === role);
  if (!result) {
    return { key: null, color: null, value: 'No encontrado' };
  }
  return result;
}

/**
 * Parse nudge status
 * @param {Boolean} sent
 * @param {Boolean} isDraft
 * @param {Boolean} archived
 * @param {String} type
 * @param {String} dateTimeRangeStart
 * @param {String} dateTimeRangeEnd
 */
export function parseNudgeStatus(sent, isDraft, archived, type, dateTimeRangeStart, dateTimeRangeEnd) {
  let status = null;
  if (archived) {
    status = NUDGE_STATUSES.ARCHIVED;
  } else if (isDraft) {
    status = NUDGE_STATUSES.DRAFT;
  } else if (type === TIME_BASED) {
    if (sent == 4) {
      status = NUDGE_STATUSES.INPROGRESS;
    }
    else if (sent == 1) {
      status = NUDGE_STATUSES.SENT;
    }
    else {
      status = NUDGE_STATUSES.SCHEDULED;
    }
  } else if (type === GEOLOCATION) {
    const today = moment().format('YYYY-MM-DD');
    const dateStart = moment(dateTimeRangeStart).format('YYYY-MM-DD');
    const dateEnd = moment(dateTimeRangeEnd).format('YYYY-MM-DD');
    if (
      (dateStart && today < dateStart)
    ) {
      status = NUDGE_STATUSES.SCHEDULED;
    } else if (dateEnd && today > dateEnd) {
      status = NUDGE_STATUSES.COMPLETED;
    } else {
      status = NUDGE_STATUSES.ACTIVE;
    }
  }
  return status;
}

// Tempfix until #812 #814 and #884 be merged
/**
 * Get user from session storage
 */
export function getUser() {
  if (isJSON(sessionStorage.getItem('USER'))) {
    return JSON.parse(sessionStorage.getItem('USER'));
  }
  return null;
}

export function camelize(str) {
  return str.replace(/[^A-Za-z0-9]/g, ' ').replace(/^\w|[A-Z]|\b\w|\s+/g, (match, index) => {
    if (+match === 0) return ''; // or if (/\s+/.test(match)) for white spaces
    return index === 0 ? match.toLowerCase() : match.toUpperCase();
  });
}

export const formatPercentage = (value) => {
  const formattedValue = value.toFixed(1);
  return ` ${formattedValue}%`;
};

export const formatNumber = (value) => new Intl.NumberFormat().format(value);

export const formatNumberPercentage = (value) => {
  return `${new Intl.NumberFormat().format(value)}%`
};

export const formatCampaigns = (campaignList) => {
  const modifiedCampaignData = campaignList.map(
    (campaign) => (
      {
        ...campaign,
        description: campaign.description.length > 100 ? campaign.description.slice(0, 100).concat(' ...') : campaign.description,
        created: moment(campaign.created).format('MM/DD/YYYY'),
      }),
  );
  return modifiedCampaignData;
};

export const organizationIdJoin = (organizations) => organizations.reduce((seed, org) => {
  seed.concat(`_${org.id.toString() || 'no organization'}`);
  return seed;
}, '');

export const colorCampaignStatusAlignment = (status) => {
  const allStatusColorAlignments = {
    Archived: 'purple-2',
    Pending: 'orange',
    Paused: 'orange',
    Scheduled: 'green-2',
    Active: 'green-2',
    Completed: 'blue-2',
  };
  return allStatusColorAlignments[status];
};

export const getUserFeature = async (featureName, flagNumber) => {
  const user = await getUser();
  if (flagNumber) {
    return await user && user.featureFlags && user.featureFlags[featureName] === flagNumber;
  }
  const userHasFeature = user && user.featureFlags && user.featureFlags[featureName] ? true : false;
  return userHasFeature;
};

/**
 * Print pretty time form datetime
 * @param {Date} datetime
 */
export const printPrettyTimeFromDatetime = (datetime) => {
  const date = (new Date(datetime.replace(/-/g, '/')));
  const prettyDate = moment(date).format('LT');
  return prettyDate;
};

/**
 * Determine which button should be selected at when entering a form with radial buttons
 */
export const determineSelectedButton = async (buttonSelection, setButtonSelection, id) => {
    const newSelectedButtons = buttonSelection.map((button) => {
        if (typeof id === 'boolean'){
            if (button.value !== id) {
                button.selected = false;
            }
            if (button.value === id) {
                button.selected = true;
            }
            return button;
        }
        if (button.value !== parseInt(id)) {
            button.selected = false;
        }
        if (button.value === parseInt(id)) {
            button.selected = true;
        }
        return button;
    })
    await setButtonSelection(newSelectedButtons)
    return newSelectedButtons
}
